<template>
<b-card title="Upload Data RSP">
  <!-- <b-card-text>This is your second page.</b-card-text> -->
  <b-card-text>Please make sure the Data for upload is csv file and layout field is same with the data used for migration 🙏</b-card-text>

  <!-- Warranty Reg -->
  <b-modal
    v-model="promptUploadWarranty"
    title="Upload File Warranty"
    :active.sync="promptUploadWarranty"
    @hidden="reset">
    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="upload-border">
            <feather-icon
              icon="UploadIcon"
              svgClasses="h-12 w-12" /><br />
            <b-form-file
              v-model="file"
              plain
              accept=".xls,.xlsx,.csv"
              @change="previewFilesWarranty"
              style="margin-top:10px; margin-bottom:10px;"></b-form-file><br />
            <strong v-if="errorMessage" style="color:red; text-align:right;">{{ errorMessage }}</strong>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <template #modal-footer>
      <div class="w-100 d-flex justify-content-center align-items-center">
        <b-button
          v-show="!loading"
          variant="success"
          class="ml-1"
          @click="uploadDataWarrantyReg"
          :disabled="notReady">Accepted</b-button>
        <span v-show="loading">Processing {{uploadCounter}}</span>
        <b-spinner
          v-show="loading"
          label="Loading..."></b-spinner>

      </div>
    </template>
  </b-modal>

  <!-- Delivery Order -->
  <b-modal
    :no-close-on-backdrop="true"
    v-model="promptUploadDO"
    title="Upload File Delivery Order"
    :active.sync="promptUploadDO"
    @hidden="reset">
    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="upload-border">
            <feather-icon
              icon="UploadIcon"
              svgClasses="h-12 w-12" /><br />
            <b-form-file
              v-model="file"
              plain
              accept=".xls,.xlsx,.csv"
              @change="previewFilesDO"
              style="margin-top:10px; margin-bottom:10px;"></b-form-file><br />
            <strong v-if="errorMessage" style="color:red; text-align:right;">{{ errorMessage }}</strong>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <template #modal-footer>
      <div class="w-100 d-flex justify-content-center align-items-center">
        <b-button
          v-show="!loading"
          variant="success"
          class="ml-1"
          @click="uploadDataDO"
          :disabled="notReady">Accepted</b-button>
        <span v-show="loading">Processing {{finishedCounter}} / {{uploadCounter}}</span>
        <b-spinner
          v-show="loading"
          label="Loading..."></b-spinner>
      </div>
    </template>
  </b-modal>

  <!-- Inventory -->
  <b-modal
    v-model="promptUploadInventory"
    title="Upload File Inventory"
    :active.sync="promptUploadInventory"
    @hidden="reset">
    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="upload-border">
            <feather-icon
              icon="UploadIcon"
              svgClasses="h-12 w-12" /><br />
            <b-form-file
              v-model="file"
              plain
              accept=".xls,.xlsx,.csv"
              @change="previewFilesInventory"
              style="margin-top:10px; margin-bottom:10px;"></b-form-file><br />
            <strong v-if="errorMessage" style="color:red; text-align:right;">{{ errorMessage }}</strong>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <template #modal-footer>
      <div class="w-100 d-flex justify-content-center align-items-center">
        <b-button
          v-show="!loading"
          :disabled="notReady"
          variant="success"
          class="ml-1"
          @click="uploadDataInventory">Accepted</b-button>
        <span v-show="loading">Processing {{uploadCounter}}</span>
        <b-spinner
          v-show="loading"
          label="Loading..."></b-spinner>
      </div>
    </template>
  </b-modal>

  <b-card-text>
    <b-button
      variant="primary"
      @click="promptUploadInventory=true"> upload RSP Inventory</b-button>

  </b-card-text>
  <b-card-text>

    <b-button
      variant="primary"
      @click="promptUploadWarranty=true"> upload Warranty Reg</b-button>
  </b-card-text>
  <b-card-text>

    <b-button
      variant="primary"
      @click="promptUploadDO=true"> upload Delivery Order</b-button>

  </b-card-text>

</b-card>
</template>

<script>
import {
  BCard,
  BCardText
} from "bootstrap-vue";
import * as XLSX from "xlsx";
export default {
  components: {
    BCard,
    BCardText,
  },
  data() {
    return {
      loading: false,
      search: "",
      debounce: null,
      selected: [],
      descriptionItems: [5, 10, 25, 50, 100],

      formWarranty: {},

      formInventory: {},

      formDO: {},
      title: "",
      promptUploadWarranty: false,
      promptUploadDO: false,
      promptUploadInventory: false,
      file: null,
      uploadCounter: 0,
      finishedCounter: 0,
      uploadLength: 0,
      uploadArray: [],
      errorMessage: "",
      notReady: true,
      errorLogList:[]

    };
  },
  mounted() {},
  methods: {
    downloadTxtFile(title) {
      const content = this.errorLogList.join("\n");
      const blob = new Blob([content], { type: "text/plain" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      var timestamp = new Date().getTime()
      a.download = title+"-migration-"+timestamp+".txt";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
    /** Warranty Data Reg*/
    previewFilesWarranty(e) {
      this.notReady = true

      this.errorMessage = "";
      let files = e.target.files,
        f = files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, {
          type: "array"
        });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const XL_row_object = XLSX.utils.sheet_to_json(worksheet, {
          raw: false,
          defval: "",
        });
        const acceptedColumnName = [
          "address", "address2", "address3", "buildingName", "cert",
          "companyName", "createdAt", "email", "installerName", "installationDate", "items",
          "nameFirst", "nameLast", "phone", "postal", "promoCode",
          "purchaseDate", "registerStatus", "registerType", "salutation", "updatedAt",
          "updatedBy", "createdBy", "buildingType", "invoiceDate", "fileInvoice",
        ];
        const columnName = Object.keys(XL_row_object[0]);
        // console.log({columnName})
        // console.log({acceptedColumnName});
        for (let i = 0; i < acceptedColumnName.length; i++) {
          console.log(`item${[i]}`, columnName[i] !== acceptedColumnName[i])
          if (columnName[i] !== acceptedColumnName[i]) {
            this.errorMessage = "Invalid column name";
            return;
          }
        }
        this.uploadCounter = 0;
        this.uploadLength = XL_row_object.length;
        
        this.uploadArray = XL_row_object;
        this.notReady = false
        // console.log(this.uploadArray);
        /*
        if(this.uploadLength>21){
          this.errorMessage="Number of Record Exceeded 20. Please upload CSV with fewer record"
        }else{
          this.notReady = false
        }
        */
        

      }.bind(this);
      reader.readAsArrayBuffer(f);
    },
    
    addWarranty() {
      this.$store
        .dispatch("migration/addWarrantyReg", {
          ...this.formWarranty,
        })
        .then((res) => {
          if(res.message!=undefined){
            this.errorLogList.push(res.message)
          }
          if (this.uploadCounter === this.uploadLength) {
            this.$bvToast.toast("Successfully Add Warranty Registration", {
              title: "Success",
              variant: "success",
              solid: true,
            });
            this.downloadTxtFile("warranty");
          }
          
        })
        .catch((err) => {
          console.log({
            err
          });
          this.$bvToast.toast(
            `failed to create Warranty Registration`, {
              variant: "danger",
              solid: true,
              title: err.response.data.errors[0].message,
            }
          );
        });

    },
    uploadDataWarrantyReg() {
      if (this.uploadCounter === 0) {
        this.loading = true
      }

      if (this.uploadCounter < this.uploadLength) {
        setTimeout(() => {
          var data = this.uploadArray[this.uploadCounter];
          const trimmedData = Object.entries(data).reduce((acc, curr) => {
            let [key, value] = curr;
            // Checking if the key is a string
            acc[typeof key === "string" ? key.trim() : key] = value;
            return acc;
          }, {});

          let jsonItems = JSON.parse(trimmedData["items"])

          let date = trimmedData["createdAt"]
          // let dateArray = date.split("/")
          // let toInputDate = dateArray[1] + '/' + dateArray[0] + '/' + dateArray[2];

          if (date == '' || date == 'NULL' || date == '0000-00-00 00:00:00') {
            this.formWarranty.createdAt = new Date(0)
            this.formWarranty.updatedAt = new Date(0)
          } else {
            this.formWarranty.createdAt = trimmedData["createdAt"]
            this.formWarranty.updatedAt = trimmedData["updatedAt"]
          }
          let purchaseDate = trimmedData["purchaseDate"]
          if (purchaseDate == '' || purchaseDate == 'NULL' || purchaseDate == '0000-00-00 00:00:00') {
            this.formWarranty.purchaseDate = new Date(0)
          } else {
            this.formWarranty.purchaseDate = purchaseDate;
          }

          let installationDate = trimmedData["installationDate"]
          if (installationDate == '' || installationDate == 'NULL' || installationDate == '0000-00-00 00:00:00') {
            this.formWarranty.installationDate = new Date(0)
          } else {
            this.formWarranty.installationDate = installationDate;
          }

          this.formWarranty.address = trimmedData["address"];
          this.formWarranty.address2 = trimmedData["address2"];
          this.formWarranty.address3 = trimmedData["address3"];
          this.formWarranty.buildingName = trimmedData["buildingName"];
          this.formWarranty.cert = trimmedData["cert"];
          this.formWarranty.companyName = trimmedData["companyName"];
          this.formWarranty.email = trimmedData["email"];
          this.formWarranty.installerName = trimmedData["installerName"];
          this.formWarranty.items = jsonItems;
          this.formWarranty.nameFirst = trimmedData["nameFirst"];
          this.formWarranty.nameLast = trimmedData["nameLast"];
          this.formWarranty.phone = trimmedData["phone"];
          this.formWarranty.postal = trimmedData["postal"];
          this.formWarranty.promoCode = trimmedData["promoCode"];
          this.formWarranty.registerStatus = trimmedData["registerStatus"];
          this.formWarranty.registerType = trimmedData["registerType"];
          this.formWarranty.salutation = trimmedData["salutation"];
          this.formWarranty.updatedBy = trimmedData["updatedBy"];
          this.formWarranty.createdBy = trimmedData["createdBy"];
          this.formWarranty.buildingType = trimmedData["buildingType"];
          this.formWarranty.invoiceDate = new Date(0);
          this.formWarranty.fileInvoice = trimmedData["fileInvoice"];

          this.uploadCounter++;
          this.addWarranty();

          this.uploadDataWarrantyReg()

        }, 50);
      } else {
        this.file = null;
        this.promptUpload = false;
        this.loading = false
      }
    },

    /** Delivery Order */
    previewFilesDO(e) {
      this.notReady = true

      this.errorMessage = "";
      let files = e.target.files,
        f = files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, {
          type: "array"
        });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const XL_row_object = XLSX.utils.sheet_to_json(worksheet, {
          raw: false,
          defval: "",
        });
        const acceptedColumnName = ["CustomerName1", "CustomerName2", "DO_details", "customerPo",
          "dealerNo", "deliveryAddress1", "deliveryAddress2", "deliveryAddress3", "deliveryCity", "deliveryDate",
          "deliveryPostalCode", "distributionChannel", "doType", "email", "orderDate", "pcode", "phone", "plant",
          "priority", "projectName", "salesPersonCode", "salesman", "sapCreatedBy", "sapDo", "sapSo", "shippingPoint",
          "soType", "status", "updatedAt", "createdAt", "updatedBy", "createdBy"
        ];
        const columnName = Object.keys(XL_row_object[0]);
        // console.log({columnName})
        // console.log({acceptedColumnName});
        for (let i = 0; i < acceptedColumnName.length; i++) {
          console.log(`item${[i]}`, columnName[i] !== acceptedColumnName[i])
          if (columnName[i] !== acceptedColumnName[i]) {
            this.errorMessage = "Invalid column name";
            return;
          }
        }
        this.uploadCounter = 0;
        this.finishedCounter = 0;
        this.uploadLength = XL_row_object.length;
        this.uploadArray = XL_row_object;
        this.notReady = false
        // console.log(this.uploadArray);
        /*
        if(this.uploadLength>21){
          this.errorMessage="Number of Record Exceeded 20. Please upload CSV with fewer record"
        }else{
          this.notReady = false
        }
        */

      }.bind(this);
      reader.readAsArrayBuffer(f);
    },
    async addDO(doData) {
      let form = {
        ...doData
      }
      let res;

      do {
        res = await this.$store
          .dispatch("migration/addDORSP", form)
        if (res.status==false) {
          this.$bvToast.toast("Upload DO " + this.formDO.sapDo + " failed, retrying", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          this.errorLogList.push(res.message);
        }
      } while (res.status==false);
      if(res.message!==undefined){
        this.errorLogList.push(res.message);
      }
      this.finishedCounter++;
      if (this.finishedCounter === this.uploadLength) {
        this.$bvToast.toast("Delivery Order Migration Completed", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.downloadTxtFile("delivery-order");
        this.promptUploadDO=false;
        
      }

    },
    uploadDataDO() {
      if (this.uploadCounter === 0) {
        this.loading = true
      }

      if (this.uploadCounter < this.uploadLength) {
        setTimeout( () => {
          var data = this.uploadArray[this.uploadCounter];
          console.log({
            data
          })
          const trimmedData = Object.entries(data).reduce((acc, curr) => {
            let [key, value] = curr;
            // Checking if the key is a string
            acc[typeof key === "string" ? key.trim() : key] = value;
            return acc;
          }, {});
          let json = JSON.parse(trimmedData["DO_details"])

          let date = trimmedData["createdAt"]
          let ordDate = trimmedData["orderDate"]
          let delivDate = trimmedData["deliveryDate"]

          // let dateArray = date.split("/")
          // let toInputDate = dateArray[1] + '/' + dateArray[0] + '/' + dateArray[2];
          if (date == '' || date == 'NULL' || date == '0000-00-00 00:00:00') {
            this.formDO.createdAt = new Date(0)
            this.formDO.updatedAt = new Date(0)
          } else {
            this.formDO.createdAt = trimmedData["createdAt"]
            this.formDO.updatedAt = trimmedData["updatedAt"]
          }

          if (ordDate == '' || ordDate == 'NULL' || ordDate == '0000-00-00') {

            this.formDO.orderDate = new Date(0)

          } else {
            this.formDO.orderDate = trimmedData["orderDate"];
          }

          if (delivDate == '' || delivDate == 'NULL' || delivDate == '0000-00-00') {
            this.formDO.deliveryDate = new Date(0)
          } else {
            this.formDO.deliveryDate = trimmedData["deliveryDate"];
          }

          // this.formDO.createdAt =new Date (date);
          this.formDO.CustomerName1 = trimmedData["CustomerName1"];
          this.formDO.CustomerName2 = trimmedData["CustomerName2"];
          this.formDO.DO_details = json;
          this.formDO.customerPo = trimmedData["customerPo"];
          this.formDO.dealerNo = trimmedData["dealerNo"];
          this.formDO.deliveryAddress1 = trimmedData["deliveryAddress1"];
          this.formDO.deliveryAddress2 = trimmedData["deliveryAddress2"];
          this.formDO.deliveryAddress3 = trimmedData["deliveryAddress3"];
          this.formDO.deliveryCity = trimmedData["deliveryCity"];
          this.formDO.deliveryPostalCode = trimmedData["deliveryPostalCode"];
          this.formDO.distributionChannel = trimmedData["distributionChannel"];
          this.formDO.doType = trimmedData["doType"];
          this.formDO.email = trimmedData["email"];
          this.formDO.pcode = trimmedData["pcode"];
          this.formDO.phone = trimmedData["phone"];
          this.formDO.plant = trimmedData["plant"];
          this.formDO.priority = trimmedData["priority"];
          this.formDO.projectName = trimmedData["projectName"];
          this.formDO.salesPersonCode = trimmedData["salesPersonCode"];
          this.formDO.salesman = trimmedData["salesman"];
          this.formDO.sapCreatedBy = trimmedData["sapCreatedBy"];
          this.formDO.sapDo = trimmedData["sapDo"];
          this.formDO.sapSo = trimmedData["sapSo"];
          this.formDO.shippingPoint = trimmedData["shippingPoint"];
          this.formDO.soType = trimmedData["soType"];
          this.formDO.status = trimmedData["status"];
          // this.formDO.updatedAt = trimmedData["updatedAt"];
          this.formDO.updatedBy = trimmedData["updatedBy"];
          this.formDO.createdBy = trimmedData["createdBy"];

          this.uploadCounter++;

          this.addDO(this.formDO);
          

          this.uploadDataDO()
          // this.addNewBleachingEarth("Upload");
          // this.uploadBleachingEarth();
        }, 120);
      } else {
        this.file = null;
        this.promptUpload = false;
        this.loading = false
      }
    },

    /** Inventory */
    previewFilesInventory(e) {
      this.notReady = true
      this.errorMessage = "";
      let files = e.target.files,
        f = files[0];
      const reader = new FileReader();
      reader.onload = function (e) {

        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, {
          type: "array",
        });

        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        const XL_row_object = XLSX.utils.sheet_to_json(worksheet, {
          raw: false,
          defval: "",
        });
        console.log({
          XL_row_object
        });
        const acceptedColumnName = ["barcode", "certNo", "compressorWarrantyDuration", "compressorWarrantyEndDate", "createdAt",
          "dealer", "dealerCode", "description", "drccOrder", "generalWarrantyDuration", "generalWarrantyEndDate", "itemCatalogue",
          "itemModel", "pcode", "phlvl1", "phlvl2", "registerDate", "residentialWarrantyDuration", "residentialWarrantyEndDate",
          "sapDo", "sapSo", "scanStatus", "serial", "shippingPoint", "skip", "updatedAt", "updatedBy", "warrantyStatus", "flagExpiry", "usedPcode", "industryType", "createdBy"
        ];
        const columnName = Object.keys(XL_row_object[0]);
        // console.log({columnName})
        // console.log({acceptedColumnName});
        for (let i = 0; i < acceptedColumnName.length; i++) {
          console.log(`item${[i]}`, columnName[i] !== acceptedColumnName[i])
          if (columnName[i] !== acceptedColumnName[i]) {
            this.errorMessage = "Invalid column name";
            return;
          }
        }
        this.uploadCounter = 0;
        this.uploadLength = XL_row_object.length;
        this.uploadArray = XL_row_object;
        this.notReady = false
        /*
        if(this.uploadLength>21){
          this.errorMessage="Number of Record Exceeded 20. Please upload CSV with fewer record"
        }else{
          this.notReady = false
        }
        */
      }.bind(this);
      reader.readAsArrayBuffer(f);
    },
    addInventory() {
      this.$store
        .dispatch("migration/addInventoryRSP", {
          ...this.formInventory,
        })
        .then((res) => {
          if(res.message!=undefined){
            this.errorLogList.push(res.message)
          }
          if (this.uploadCounter === this.uploadLength) {
            this.$bvToast.toast("Inventory Migration Completed", {
              title: "Success",
              variant: "success",
              solid: true,
            });
            this.downloadTxtFile("inventory");
          }

        })
        .catch((err) => {
          console.log({
            err
          });
          this.$bvToast.toast(
            `failed to create Inventory`, {
              variant: "danger",
              solid: true,
              title: err.response.data.errors[0].message,
            }
          );

        });

    },
    uploadDataInventory() {
      if (this.uploadCounter === 0) {
        this.loading = true
      }

      if (this.uploadCounter < this.uploadLength) {
        console.log('counter', this.uploadCounter)
        console.log('length', this.uploadLength)
        setTimeout(async () => {
          var data = this.uploadArray[this.uploadCounter];
          console.log({
            data
          })
          const trimmedData = Object.entries(data).reduce((acc, curr) => {
            let [key, value] = curr;
            // Checking if the key is a string
            acc[typeof key === "string" ? key.trim() : key] = value;
            return acc;
          }, {});
          // let jsonPcodes = JSON.parse(trimmedData["pCodes"])

          let date = trimmedData["createdAt"]
          // console.log({date})
          //condition date null
          if (date == '' || date == 'NULL' || date == '0000-00-00 00:00:00') {
            this.formInventory.createdAt = new Date(0)
            this.formInventory.updatedAt = new Date(0)
          } else {
            this.formInventory.createdAt = trimmedData["createdAt"];
            this.formInventory.updatedAt = trimmedData["updatedAt"];
          }
          //condition registerDate null
          let registerDate = trimmedData["registerDate"];
          if (registerDate === 'NULL') {
            this.formInventory.registerDate = new Date(0)
          } else {
            this.formInventory.registerDate = trimmedData["registerDate"];
          }
          //condition certNo null
          if (trimmedData["certNo"] === 'NULL') {
            this.formInventory.certNo = null
          } else {
            this.formInventory.certNo = trimmedData["certNo"];;
          }
          //condition Compressor Warranty Date null
          if (trimmedData["compressorWarrantyEndDate"] === 'NULL') {
            this.formInventory.compressorWarrantyEndDate = null
          } else {
            this.formInventory.compressorWarrantyEndDate = trimmedData["compressorWarrantyEndDate"];;
          }
          //condition General Warranty Date null
          if (trimmedData["generalWarrantyEndDate"] === 'NULL') {
            this.formInventory.generalWarrantyEndDate = null
          } else {
            this.formInventory.generalWarrantyEndDate = trimmedData["generalWarrantyEndDate"];;
          }
          //condition Residential Warranty Date null
          if (trimmedData["residentialWarrantyEndDate"] === 'NULL') {
            this.formInventory.residentialWarrantyEndDate = null
          } else {
            this.formInventory.residentialWarrantyEndDate = trimmedData["residentialWarrantyEndDate"];;
          }
          //condition customer null
          if (trimmedData["customer"] === 'NULL') {
            this.formInventory.customer = null
          } else {
            this.formInventory.customer = trimmedData["customer"];;
          }
          this.formInventory.barcode = trimmedData["barcode"];
          this.formInventory.certNo = trimmedData["certNo"];
          this.formInventory.dealer = trimmedData["dealer"];
          this.formInventory.dealerCode = trimmedData["dealerCode"];
          this.formInventory.description = trimmedData["description"];
          this.formInventory.drccOrder = trimmedData["drccOrder"];
          this.formInventory.itemCatalogue = trimmedData["itemCatalogue"];
          this.formInventory.itemModel = trimmedData["itemModel"];
          this.formInventory.phlvl1 = trimmedData["phlvl1"];
          this.formInventory.phlvl2 = trimmedData["phlvl2"];
          this.formInventory.sapDo = trimmedData["sapDo"];
          this.formInventory.sapSo = trimmedData["sapSo"];
          this.formInventory.scanStatus = trimmedData["scanStatus"];
          this.formInventory.serial = trimmedData["serial"];
          this.formInventory.shippingPoint = trimmedData["shippingPoint"];
          this.formInventory.skip = trimmedData["skip"];
          this.formInventory.warrantyStatus = trimmedData["warrantyStatus"];
          this.formInventory.pcode = trimmedData["pcode"];
          this.formInventory.industryType = trimmedData["industryType"];
          this.formInventory.flagExpiry = trimmedData["flagExpiry"];
          this.formInventory.generalWarrantyDuration = trimmedData["generalWarrantyDuration"];
          this.formInventory.compressorWarrantyDuration = trimmedData["compressorWarrantyDuration"];
          this.formInventory.residentialWarrantyDuration = trimmedData["residentialWarrantyDuration"];

          // this.formBatch.usedDate = trimmedData["usedDate"];
          // this.formBatch.useBy = trimmedData["useBy"];

          this.formInventory.updatedBy = trimmedData["updatedBy"];
          this.formInventory.createdBy = trimmedData["createdBy"];

          this.uploadCounter++;

          this.addInventory();

          this.uploadDataInventory()
          // this.addNewBleachingEarth("Upload");
          // this.uploadBleachingEarth();
        }, 55);
      } else {
        this.file = null;
        this.promptUpload = false;
        this.loading = false
      }
    },
    reset() {
      this.notReady = true
    }

  },
  computed: {
    // isReady () {
    //   if(this.uploadLength == this.uploadArray.length){
    //     console.log('ttrrue')
    //     this.notReady = false
    //   }else{
    //     this.notReady = true
    //   }
    // }
  },
};
</script>

<style></style>
